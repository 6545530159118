<template>
  <div class="app-container">
    <div class="wrap">
      <navBar :title="title" @handleGoBack="handleGoBack"></navBar>
      <div class="add-form">
        <van-form validate-first @failed="onFailed" @submit="onSubmit">
          <van-field
              v-model="formInline.noticeTitle"
              label="公告标题"
              placeholder="请输入"
              :required="true"
              :rules="[{ required: true, message: '请输入公告标题' }]"
          />

          <van-field
              readonly
              clickable
              name="picker"
              v-model="formInline.noticeTypeName"
              label="公告类别"
              placeholder="请选择"
              @click="showTypePicker = true"
              :required="true"
              :rules="[{ required: true, message: '请选择公告类别' }]"
          />
          <van-popup v-model="showTypePicker" position="bottom">
            <van-picker
                show-toolbar
                :columns="typeOptions"
                value-key="dictLabel"
                @confirm="
                onConfirm($event, 'noticeType', 'noticeTypeName', 'dictLabel', 'showTypePicker')
              "
                @cancel="showTypePicker = false"
            />
          </van-popup>

          <van-field
              readonly
              clickable
              v-model="formInline.noticeRangeStr"
              label="发布范围"
              placeholder="请选择"
              :required="true"
              :rules="[{ required: true, message: '请选择发布范围' }]"
              @click="showRangePicker = true"
          />

          <textAreaInput :remark="formInline.noticeContent" @change="handleContentChange"/>

          <div class="line"></div>
          <van-field name="uploader" label="附件" v-if="isShowAuth">
            <template #input>
              <van-uploader v-model="uploader" :after-read="afterRead"/>
            </template>
          </van-field>

          <van-button class="btn" block type="info" native-type="submit">提交</van-button>
        </van-form>
      </div>
    </div>

    <selectUserAndDept :showRangePicker="showRangePicker" @handleClose="handleCloseRange"/>
  </div>
</template>
<script>
import {addnotice, amendnotice} from '@/api/oa/notice';
import selectUserAndDept from '@/components/selectUserAndDept/selectUserAndDept';
import {submiteUpload} from "@/api/system/config";

export default {
  components: {selectUserAndDept},
  data() {
    return {
      formInline: {
        level: ''
      },
      showTypePicker: false,
      typeOptions: [],
      title: '发布公告',
      //发布范围弹窗
      showRangePicker: false,
      uploader: [],
      uploadParams: {
        businessType: 'oa_notice',
        businessId: undefined,
        file: null,
      },
      isShowAuth: localStorage.getItem('isShowAuth') === '1'
    };
  },

  mounted() {
    //查询字典
    this.findDict()
    //回显
    this.findDetail()
  },

  methods: {
    handleGoBack() {
      this.$router.replace('/oa/oaNotice');
      this.$router.go(-1)
    },

    //查询字典
    async findDict() {
      // 公告类型
      await this.getDicts('notice_type').then((response) => {
        this.typeOptions = response.data;
      });
    },

    //回显
    findDetail() {
      const itemDetail = this.$route.query.obj ? JSON.parse(this.$route.query.obj) : '';
      if (itemDetail) {
        this.formInline = itemDetail;
        this.title = '编辑公告';
      } else {
        this.formInline = {};
        this.title = '发布公告';
      }
    },

    //公告内容
    handleContentChange(val) {
      this.formInline.noticeContent = val;
    },

    //公告类别确认
    onConfirm(event, key, keyName, eventKey, visible) {
      this.formInline[key] = event['dictValue'];
      this.formInline[keyName] = event[eventKey];
      this[visible] = false;
    },

    //关闭发布范围弹窗
    handleCloseRange(list, applyType) {
      this.showRangePicker = false;

      this.formInline.noticeRangeType = applyType;
      if (list && list.length > 0) {
        this.formInline.noticeRange = list.map((item) => item.id).join(",");
        this.formInline.noticeRangeStr = list.map((item) => item.name).join(',');
      } else {
        this.formInline.noticeRangeStr = '所有人'
      }
    },

    async onSubmit(values) {
      const params = {
        ...values,
        ...this.formInline,
      };
      // const data = this.objectToFormData(params);
      const data = JSON.stringify(this.formInline)
      const result = this.formInline.id ? await amendnotice(data) : await addnotice(params);
      if (result.code == 200) {
        this.$toast.success('操作成功');
        if (this.uploadParams.file) {
          this.uploadParams.businessId = result.data
          setTimeout(async () => {
            await submiteUpload(this.uploadParams);
          }, 1000);
        }
        this.handleGoBack();
      }
    },

    onFailed(errorInfo) {
      console.log('failed', errorInfo);
    },

    afterRead(file) {
      // 此时可以自行将文件上传至服务器
      this.uploadParams.file = file.file;
    },
  },
};
</script>

<style lang="scss" scoped>
.base-tit {
  padding: 20px 30px;
  font-size: 24px;
  color: #666666;
}

.btn {
  width: 710px;
  height: 100px;
  background: #0091ff;
  border-radius: 6px 6px 6px 6px;
  margin: 118px auto 0;
  font-size: 38px;
}

// 导航样式 --start
::v-deep .van-hairline--bottom::after {
  border-bottom: none;
}

::v-deep .van-nav-bar .van-icon {
  color: #000;
}

::v-deep .van-nav-bar__content {
  height: 100px;
  line-height: 100px;
}

::v-deep .van-nav-bar__arrow,
::v-deep .van-nav-bar .van-icon {
  font-size: 32px;
}

// 导航样式 --end
</style>
